import { Stack, Box } from "@mui/material"
import { BingoBoardItem } from "./BingoBoardItem"

export interface BingoBoardProps {
    layout: Array<Array<string>>,
    checked: Array<string>
}

export const BingoBoard: React.FunctionComponent<BingoBoardProps> = (props) => {
    const rowsCount = props.layout.length;
    const columnsCount = props.layout[0].length;

    return (
        <Stack alignItems='center'>
            <h1>
                Postelové bingo
            </h1>
            <Box sx={{
                display: 'grid',
                gridAutoFlow: 'row',
                gridTemplateColumns: 'repeat('+ columnsCount + ', 1fr)',
                gridTemplateRows: 'repeat('+ rowsCount + ', 1fr)',
                gap: 1
            }}>
                {
                    props.layout.map(
                        row =>
                            row.map(column =>
                                <BingoBoardItem
                                    title={column}
                                    checked={props.checked.includes(column)} />
                            ))
                }
            </Box>
        </Stack>

    )
} 