import { Stack, TextField, Button } from "@mui/material";
import { useState } from "react";


export interface PasswordFormProps {
    onSubmit(password: string): void
}

export const PasswordFormContainer: React.FunctionComponent<PasswordFormProps> = (formProps) => {
    const [password, setPassword] = useState('');

    return (
        <form>
            <Stack spacing={2}>
                <TextField id='password'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    label='Enter password' />
                <Button variant='contained'
                    type='submit'
                    onClick={(e) => formProps.onSubmit(password)}>
                    Enter
                </Button>
            </Stack>
        </form>
    )
};