import { Button } from "@mui/material";
import React from "react"

export interface BingoBoardItemProps {
    title: string,
    checked: boolean
}

export const BingoBoardItem : React.FunctionComponent<BingoBoardItemProps> = (props) => {

    return (
        <Button variant='contained'
            color={props.checked ? 'success' : 'info'}
            style={{ width: '6em', height: '6em' }}>
            {props.title}
        </Button>
    )
};