import React from 'react';
import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { BingoContainer } from './Containers/BingoContainer';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export const App: React.FunctionComponent = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box display='flex'
        minHeight='100vh'
        justifyContent='center'
        alignItems='center'>
        <BingoContainer />
      </Box>
    </ThemeProvider>);
}