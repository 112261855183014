import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PasswordFormContainer } from './PasswordForm';
import { BingoBoard } from '../Components/BingoBoard';

interface Bingo {
    layout: Array<Array<string>>,
    checked: Array<string>
}

export const BingoContainer: React.FunctionComponent = () => {
    const [bingo, setBingo] = useState<Bingo | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const promise = new Promise(resolve => setTimeout(resolve, 1000));
            await promise;
            const loadedData = {
                layout: [
                    ["Ložnice", "Kuchyň", "Les"],
                    ["Obývák", "Koupelna", "Chodba"],
                    ["Sprcha", "Pracovní stůl", "Balkón"],
                ],
                checked: ["Ložnice", "Obývák", "Kuchyň", "Koupelna"]
            }
            setBingo(loadedData);
            setIsLoading(false);
        }
        if (!isLoading)
            return;
        fetchData()
    }, [isLoading])

    const onSubmitPassword = (password: string) => {
        if (password === 'Minach') {
            setIsLoading(true);
        }
    }

    if (isLoading) {
        return (
            <CircularProgress />
        )
    }

    if (!bingo) {
        return (
            <PasswordFormContainer onSubmit={onSubmitPassword} />
        );
    }

    return (
        <BingoBoard
            layout={bingo.layout}
            checked={bingo.checked} />
    );
}